
import { Component } from "vue-property-decorator";
import HospitalHeader from "@/components/HospitalHeader.vue";
import Table from "@/components/Table.vue";
import ManagementFooter from "@/components/ManagementFooter.vue";
import CheckCommonLogin from "@/utils/CheckCommonLogin";
import Button from "@/components/Button.vue";

@Component({
  name: "ReservationManagement",
  components: {
    HospitalHeader,
    Table,
    ManagementFooter,
    Button,
  },
})
export default class ReservationManagement extends CheckCommonLogin {
  private noticeTitle = "";

  private sendMail = false;

  private headers = [
    { text: "生体番号", value: "vitalNumber", sortable: false },
    { text: "どうぶつ名", value: "animalName", sortable: false },
    { text: "どうぶつ種", value: "animalType", sortable: false },
    { text: "品種", value: "animalDescription", sortable: false },
    { text: "ペット年齢", value: "animalAge", sortable: false },
    { text: "顧客ID", value: "customerId", sortable: false },
    { text: "飼い主名前", value: "ownerName", sortable: false },
  ];

  private reservationItems = [
    {
      vitalNumber: "P01A0023",
      animalName: "マッシュ",
      animalDescription: "ドーベルマン",
      animalType: "犬",
      animalAge: "8",
      customerId: "KL00451",
      ownerName: "山磨　祐久",
    },
  ];
}
